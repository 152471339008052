import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PreviewCompatibleImage from 'components/PreviewCompatibleImage';

export default function BannerImage() {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "banner.svg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
      }
    }
  `);

  return (
    <PreviewCompatibleImage
      imageInfo={data.placeholderImage}
      style={{ width: '100%', height: '100%' }}
    />
  );
}
